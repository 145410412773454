import create from 'zustand';

interface IUser {
    id: number;
    name: string;
    username: string;
    email: string;
}

interface Ivideo{ 
    id: number; 
    fileName: string;
    lien: string; 
    miniature: string; 
    title: string; 
}

interface IUserStore {
    oUser: IUser | null;
    bLoading: boolean;
    lien: Ivideo | undefined;
    setVideo: (lien: Ivideo | undefined) => void;
    setUser: (oUser: IUser | null) => void;
    setLoading: (bLoading: boolean) => void;
}

export const useUserStore = create<IUserStore>((set, get) => ({
    oUser: null,
    bLoading: true,
    lien: undefined,
    setVideo: (lien) => set((state) => ({...state, lien})),
    setUser: (oUser) => set((state) => ({...state, oUser})),
    setLoading: (bLoading) => set((state) => ({...state, bLoading}))
}))