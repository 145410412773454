import React, { PropsWithChildren } from 'react';

import { useUserStore } from '../../store/user';
import { getUserConnected } from '../../api/api';

import { getUserInfo } from "../../utils/cookies" ;
import { isConnected } from '../../utils/utils';

export default function UserProvider ({ children }: PropsWithChildren) {
    const {
        setUser
    } = useUserStore();

    React.useEffect(() => {        
        if (isConnected()) {
            setUser(getUserInfo())
        }        
        // getUserConnected().then((res) => {
        //     setLoading(false);
        //     setUser(res.data);
        // }).catch((error) => {
        //     setLoading(false);
        // })
    }, [])

    return (
        <>
            {children}
        </>
    )
}