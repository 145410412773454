import { remove } from "remove-accents";
import escapeStringRegexp from 'escape-string-regexp';
//@ts-ignore
import * as He from "he";
import { useUserStore } from "../store/user";
import { getUserInfo } from "./cookies" ;


export function isModeDev () {
    return (process.env.NODE_ENV == 'development');
}

// export function isConnected () {
//     const stateUser = useUserStore.getState();
//     return stateUserInfo && (stateUser.oUser !== null);
// }

export function isConnected () {
    const stateUserInfo = getUserInfo();
    return stateUserInfo;
}

export function removeAccents (strTxt: string) {
    return remove(strTxt);
}

export function unescapeHtml (strHtml: string) {
    return He.decode(strHtml);
} 

export function itextContains (strTxt: string, strSearch: string, bHtml=true) {
    strSearch = removeAccents(strSearch);

    if (bHtml) {
        let div = document.createElement('div');
        div.innerHTML = strTxt;
        strTxt = div.innerText;
    }

    strTxt = removeAccents(strTxt);
    
    return (strTxt.search(new RegExp(`.*${strSearch}.*`, 'i')) != -1);
}

export function formatFilterText (strFilter: string) {
    strFilter = strFilter.trim();
    if (! strFilter) return '';

    let aFilter = strFilter.split(' ');
    if (! Array.isArray(aFilter)) return '';

    aFilter = aFilter.filter((filter) => filter.trim().length != 0);
    aFilter = aFilter.map((filter) => escapeStringRegexp(filter));
    
    return (aFilter.length) ? aFilter.join('.*') : '';
} 