import Cookies from "js-cookie";

const user_cookies_info = "pada-user";

export const setUserInfo = (user: any) => {
    const userString = JSON.stringify(user);
    Cookies.set(user_cookies_info, userString);
};

export const getUserInfo = () => {
    const userString = Cookies.get(user_cookies_info);
    return userString ? JSON.parse(userString) : null;
};

export const removeUser = () => {
    Cookies.remove(user_cookies_info);
};
