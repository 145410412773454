import React from "react";
import type { GatsbyBrowser } from "gatsby";

import { ToastContainer } from "react-toastify";

import UserProvider from "./src/components/user/provider";

import "./src/styles/global.css";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
    element,
}) => {
    return <UserProvider>{element}</UserProvider>;
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
    element,
}) => {
    return (
        <>
            <ToastContainer />
            {element}
        </>
    );
};
